import axios from "axios"

export enum LogTypes {
    visit = "visit",
    calculate = "calculate",
    callTaxi = "callTaxi",
    whatsappButton = "whatsappButton",
    phone = "phone",
    ads = "ads",
    keyword = "keyword",
    friendClick = "friendClick",
    il = "il",
    closed = "closed"

}

export const logAction = (type: LogTypes, data?: any) => {
    axios.get('https://geolocation-db.com/json/')
    .then(res =>{
        axios.post('https://www.turkpark.com.tr:2222/heytaksi/log', {ip_address: res.data.IPv4, type: type, data: data, il: res.data.city})
    })
    .catch(err =>{
        console.log("LogAction Error:", err);
    })
}