import React, { useState, useEffect, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import '../css/services.css';
import { render } from '@testing-library/react';
import { faBedPulse } from '@fortawesome/free-solid-svg-icons';
import { logAction, LogTypes } from '../helpers/logActions';

function Banner() {
    const [renderState, setRender] = useState(window.screen.width);
    const [src, setSrc] = useState('img/banner.mp4');
    const videoRef = useRef<any>();
    const value: string = "https://api.whatsapp.com/send/?phone=905516879051/&text=Merhaba, arkadaşımı davet etmek ve ikimiz için 25 TL indirim istiyorum.&app_absent=0"

    const handleResize = (val:any) => {
        setRender(val.target.innerWidth);
        if(val.target.innerWidth > 996){
            setSrc("img/banner-uzun.mp4");
        }else{
            setSrc("img/banner.mp4");
        }

    };

    const handleClick = () => {
        logAction(LogTypes.friendClick);
    };

    useEffect(()=>{
        if(window.screen.width > 996){
            setSrc("img/banner-uzun.mp4");
        }else{
            setSrc("img/banner.mp4");
        }
        videoRef.current.play();
        const event = window.addEventListener('resize', handleResize);

        return ()=>window.removeEventListener('resize', handleResize);
    },[]);

    

    return (
         <Row>
            <Col className='p-0 d-flex position-relative'>
                { src === 'img/banner.mp4' &&
                <Button type="button" onClick={handleClick} style={{width:"200px", height:"40px",position:"absolute",bottom:"7.8%", left:"6%", background:"#000", border:0,fontSize:"1.5rem", padding:0, borderRadius:24}}>
                    Tıkla
                </Button>
                }
                <a href={value} className='d-flex p-0 w-100' onClick={handleClick}>
                    <video ref={videoRef} src={src} className='w-100 p-0' autoPlay muted loop></video>
                </a>
            </Col>
        </Row>

    )
}

export default Banner;