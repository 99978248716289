import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { SocketContext, client } from './controller/Context';

ReactDOM.render(
    <SocketContext.Provider value={client}>
      <App />
    </SocketContext.Provider>,
  document.getElementById('root')
);