import {
  faArrowRight,
  faLocation,
  faLocationDot,
  faLocationPin,
  faMarker,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { handleCalculation, reverseGeocode } from "../../helpers/apiHelper";
import "./tt-button.css";
import TTLocationInput from "./TTLocationInput";

interface TTButtonProps {
  onSubmit: (calcResults: any) => void;
  setOpen: (value: boolean) => void;
}

function TTButton(props: TTButtonProps) {
  const { onSubmit, setOpen } = props;
  const [selectedAddress, setSelectedAddress] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inputContainer = useRef<HTMLDivElement>(null);
  const ttButton = useRef<HTMLDivElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);

  const handleButtonClick = () => {
    setIsOpen(true);
    setOpen(true);
    if (wrapper.current) {
      wrapper.current.style.height = "100px";
    }
    if (inputContainer.current) {
      inputContainer.current.classList.add("tt-input-animation");
    }
    if (ttButton.current) {
      ttButton.current.classList.add("tt-button-animation");
    }
  };

  const handleCallTaxiClick = async () => {
    if (isOpen) {
      setIsLoading(true);
      if (selectedAddress == "") {
        alert("Lütfen varış konumunuzu giriniz.");
        setIsLoading(false);
        return;
      }

      navigator.geolocation.getCurrentPosition(
        () => {
          navigator.geolocation.getCurrentPosition(async (e) => {
            const result = await reverseGeocode(
              e.coords.latitude,
              e.coords.longitude
            );

            if (result) {
              const calculationResult = await handleCalculation(
                result,
                selectedAddress
              );
              onSubmit(calculationResult);
              setIsLoading(false);
            }
          });
        },
        () => {
          alert("Devam edebilmek için konum izni gerekmektedir.");
          setIsLoading(false);
        }
      );
    }
  };

  return (
    <div className="splash-button-wrapper" ref={wrapper}>
      {!isOpen && (
        <>
          <img src="/img/warp-text.svg" alt="Text" className="warp-text-img" />
          <div className="splash-outer-circle"></div>
          <div className="splash-inner-circle"></div>
          <div className="splash-center-circle"></div>
          <img src="/img/hand.svg" alt="Hand Icon" className="hand-icon" />
        </>
      )}
      <div className="animation-container" onClick={handleButtonClick}>
        <div className="input-container" ref={inputContainer}>
          <TTLocationInput
            onSelect={setSelectedAddress}
            placeholder="Varış konumunuzu giriniz"
            className="tt-button-input"
          />
        </div>
        <div
          className="splash-tt-button"
          ref={ttButton}
          onClick={handleCallTaxiClick}
        >
          <img src="/img/tt-logo.png" className="tt-logo" alt="TT Logo" />
          {!isLoading ? (
            <FontAwesomeIcon icon={faArrowRight} />
          ) : (
            <FontAwesomeIcon icon={faSpinner} className="spin" />
          )}
        </div>
      </div>
    </div>
  );
}

export default TTButton;
