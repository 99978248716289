import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActionMeta } from "react-select";

export enum PopupTypes {
  none,
  loginPopup,
  offerPrice,
  phonePopup,
}

interface IPopupRef {
  from: string;
}

interface IPopupState {
  popup: PopupTypes;
  popupRef?: IPopupRef;
}

const initialState: IPopupState = {
  popup: PopupTypes.none,
};

const { actions, reducer } = createSlice({
  name: "popupData",
  initialState: initialState,
  reducers: {
    setPopup(state, action: PayloadAction<PopupTypes>) {
      state.popup = action.payload;
      console.log(state);
      return state;
    },
    setPopupWithRef(
      state,
      action: PayloadAction<{ popup: PopupTypes; popupRef: IPopupRef }>
    ) {
      state.popup = action.payload.popup;
      state.popupRef = action.payload.popupRef;
      return state;
    },
  },
});

export { actions, reducer };
