import React, { useState, useEffect, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { faUser, faPhone, faStar, faCar, faCalendar, faWallet, faUsers, faLocationDot, faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../css/driverform.css';
import axios from 'axios';
import Select, { SingleValue } from 'react-select'
import {iller} from '../data/il';
import CustomInput from './Inputs/CustomInput';
import PhoneInput from 'react-phone-input-2';

function Drivers() {
    const [selectedImageURL, setSelectedImageURL] = useState<any>(null);
    type detailsProp = {
        name: string,
        surname: string,
        phone: string,
        adres: string,
        referans: string,
        il: string,
        arac_yil: string,
        arac_model: string,
        arac_marka: string,
        fotograf: File | Blob | string
    }

    const initialState: detailsProp = {
        name: '',
        surname:'',
        phone: '',
        arac_marka: '',
        arac_model:'',
        arac_yil:'',
        il: '',
        adres: '',
        referans: '',
        fotograf: '',
    }

    const [driverDetails, setDriverDetails] = useState<detailsProp>(initialState);
    
    const handleDriverSubmit = (event:any) => {
        event.preventDefault();
        let formData = new FormData();
        formData.append('name', driverDetails.name);
        formData.append('phone', driverDetails.phone);
        formData.append('adres', driverDetails.adres);
        formData.append('referans', driverDetails.referans);
        formData.append('il', driverDetails.il);
        formData.append('arac_yil', driverDetails.arac_yil);
        formData.append('arac_model', driverDetails.arac_model);
        formData.append('arac_marka', driverDetails.arac_marka);
        formData.set('file', driverDetails.fotograf);
  
        axios.post('https://www.turkpark.com.tr:2222/drivers/application',
            formData,
            {headers: {"content-type": "multipart/form-data"}}).then(()=>{
            alert('Başvurunuz başarıyla gönderildi.');
        }).catch(()=>{
            alert('Lütfen bilgilerinizi doğru girdiğinizden emin olun.')
        });
    }

    const handleSelectPhoto = (event:any) => {
        setDriverDetails({...driverDetails, fotograf: event.target.files[0]});
        setSelectedImageURL(URL.createObjectURL(event.target.files[0]));
    }

    const noOptionsMsg = () => {
        return (
            <>İl bulunamadı</>
        )};

    const ilOptions = [
        { value: 'İzmir', label: 'İzmir' },
        { value: 'Antalya', label: 'Antalya' },
        { value: 'Bodrum', label: 'Bodrum' },
        { value: 'Fethiye', label: 'Fethiye' },
        { value: 'Çeşme', label: 'Çeşme' }
    ];

    return (
        <>
        <Row id="driverform-mobile" className="d-flex d-lg-none align-items-center">
            <Col xs={12} className="form-wrapper-mobile d-flex flex-column align-items-center justify-content-center">
                <div className='bar'></div>
                <div className="custom-form-container-mobile px-3 d-flex justify-content-center">
                    <Form onSubmit={handleDriverSubmit} className="w-100 px-2">
                    <div className='user-logo-image w-100 d-flex align-items-center justify-content-center'>
                            <input type="file" onChange={handleSelectPhoto} className="file-input" accept='image/png, image/jpeg' required />
                            <div className='upload-image-icon-mobile'/>
                            <div className='rounded-circle overflow-hidden'>
                                <img src={selectedImageURL ? selectedImageURL : "img/profile.png"} className={selectedImageURL ? "img-fluid" : "img-fluid"} style={{width: "100%",height: "100%"}}></img>
                            </div>
                    </div>
                    <Row>
                        <Col xs={12} className="driver-form-header">
                            Sürücü Başvuru Formu
                        </Col>
                    </Row>
                        <Row>
                            <Col>
                            <CustomInput text="Adınız" icon={null} data={driverDetails} setData={setDriverDetails} name="name" require={true}/>
                                {/* <FloatingLabel controlId="floatingTextarea2" label="Comments">
                                <InputGroup className="mb-4 driver-form-group">
                                    <FormControl as="textarea" className='driver-form-input' placeholder="Leave a comment here" required onChange={(e)=>setDriverDetails({...driverDetails, name: e.target?.value})}/>
                                    <InputGroup.Text className='driver-form-icon' id="basic-addon1">
                                        <FontAwesomeIcon icon={faUser} />
                                    </InputGroup.Text>
                                </InputGroup>
                                    </FloatingLabel> */}
                            </Col>
                            <Col>
                                    <CustomInput text="Soyadınız" icon={null} data={driverDetails} setData={setDriverDetails} name="surname" require={true}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            {/* <CustomInput text="Telefon" icon={faPhone} data={driverDetails} setData={setDriverDetails} name="phone" require={true}/> */}
                            <PhoneInput
                            containerStyle={{marginBottom: "15px"}}
                            containerClass="call-taxi-phone-input-wrapper"
                            inputClass="call-taxi-phone-input"
                            country={'tr'}
                            specialLabel=""
                            placeholder="+90 (5xx) xxx xx xx xx"
                            countryCodeEditable={false}
                            masks={{tr: '(...) ...-..-..'}}
                            onChange={(e:any) => {
                                setDriverDetails({...driverDetails, phone: e})
                            }}
                            value={driverDetails.phone}
                            />  
                                {/* <InputGroup className="mb-4 driver-form-group">
                                    <FormControl className='driver-form-input'placeholder="Telefon" aria-label="Telefon" aria-describedby="basic-addon1" required onChange={(e)=>setDriverDetails({...driverDetails, phone: e.target?.value})}/>
                                    <InputGroup.Text className='driver-form-icon' id="basic-addon1">
                                        <FontAwesomeIcon icon={faPhone} />
                                    </InputGroup.Text>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <CustomInput text="Araç Markası (Zorunlu)" icon={faStar} data={driverDetails} setData={setDriverDetails} name="arac_marka" require={true}/>

                                {/* <InputGroup className="mb-4 driver-form-group">
                                    <FormControl className='driver-form-input'placeholder="Araç Markası (Zorunlu)" aria-label="Araç Markası (Zorunlu)" aria-describedby="basic-addon1" required onChange={(e)=>setDriverDetails({...driverDetails, arac:{...driverDetails.arac, arac_marka:e.target?.value}})}/>
                                    <InputGroup.Text className='driver-form-icon' id="basic-addon1">
                                        <FontAwesomeIcon icon={faStar} />
                                    </InputGroup.Text>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <CustomInput text="Araç Modeli (Zorunlu)" icon={faCar} data={driverDetails} setData={setDriverDetails} name="arac_model" require={true}/>

                                {/* <InputGroup className="mb-4 driver-form-group">
                                    <FormControl className='driver-form-input'placeholder="Araç Modeli (Zorunlu)" aria-label="Araç Modeli (Zorunlu)" aria-describedby="basic-addon1" required onChange={(e)=>setDriverDetails({...driverDetails, arac:{...driverDetails.arac, arac_model:e.target?.value}})}/>
                                    <InputGroup.Text className='driver-form-icon' id="basic-addon1">
                                        <FontAwesomeIcon icon={faCar} />
                                    </InputGroup.Text>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <CustomInput text="Araç Yılı (Zorunlu)" icon={faCalendar} data={driverDetails} setData={setDriverDetails} name ="arac_yil" require={true}/>

                                {/* <InputGroup className="mb-4 driver-form-group">
                                    <FormControl className='driver-form-input'placeholder="Araç Yılı (Zorunlu)" aria-label="Araç Yılı (Zorunlu)" aria-describedby="basic-addon1" required onChange={(e)=>setDriverDetails({...driverDetails, arac:{...driverDetails.arac, arac_yil:e.target?.value}})}/>
                                    <InputGroup.Text className='driver-form-icon' id="basic-addon1">
                                        <FontAwesomeIcon icon={faCalendar} />
                                    </InputGroup.Text>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup className="mb-3 driver-form-group flex-row">
                                    <div className='d-flex flex-row w-100'>
                                        <Select className='w-100 border-none rounded-0' noOptionsMessage={noOptionsMsg} placeholder="İl seçiniz" onChange={(e : SingleValue<{value:string, label:string}>)=>setDriverDetails({...driverDetails, il: e?.value ? e?.value : ''})} styles={{
                                            control: (base:any, state:any) => ({
                                                ...base,
                                                height:'50px',
                                                borderTopLeftRadius: '13px',
                                                borderBottomLeftRadius: '13px',
                                                border:"none",
                                                background:"#F3F6FB",
                                            }),
                                            indicatorSeparator: (base:any, state:any) => ({
                                                ...base,
                                                display: 'none',
                                            }),
                                            
                                        }}
                                        options={ilOptions}
                                        />
                                            <InputGroup.Text className='driver-form-icon' id="basic-addon1">
                                                <FontAwesomeIcon icon={faStar} />
                                            </InputGroup.Text>
                                        <FormControl className='fake-input' onChange={undefined} value={driverDetails.il} required/>
                                    </div>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <CustomInput text="Adres" icon={faLocationDot} data={driverDetails} setData={setDriverDetails} name="adres" require={true}/>

                                {/* <InputGroup className="mb-4 driver-form-group">
                                    <FormControl className='driver-form-input'placeholder="Adres" aria-label="Adres" aria-describedby="basic-addon1" required onChange={(e)=>setDriverDetails({...driverDetails, adres: e.target?.value})}/>
                                    <InputGroup.Text className='driver-form-icon' id="basic-addon1">
                                        <FontAwesomeIcon icon={faLocationDot} />
                                    </InputGroup.Text>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <CustomInput text="Referans Bilgileri" icon={faUsers} data={driverDetails} setData={setDriverDetails} name="referans" require={true}/>

                                {/* <InputGroup className="mb-4 driver-form-group">
                                    <FormControl className='driver-form-input' placeholder="Referans Bilgileri" aria-label="Referans Bilgileri" aria-describedby="basic-addon1" onChange={(e)=>setDriverDetails({...driverDetails, referans: e.target?.value})}/>
                                    <InputGroup.Text className='driver-form-icon' id="basic-addon1">
                                        <FontAwesomeIcon icon={faUsers} />
                                    </InputGroup.Text>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex align-items-center justify-content-center"> 
                                <Button variant="secondary" type='submit' style={{background: "#1D1D1F",borderRadius: "21px",width:"144px",height:"45px"}}>
                                    Gönder

                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Col>
        </Row>
        <Row id="driverform" className='d-none d-lg-flex'>
            <Col xs={6} className="form-left d-flex flex-column justify-content-center align-items-center">
                <h2 className='text-center pt-xxl-5 pt-xl-0'>SÜRÜCÜ BAŞVURU<br />FORMU</h2>
                <p className='text-center'>Sen de Bizimle Çalışmak İster misin?</p>
                <img src='img/form.svg' className='w-100'/>
            </Col>
            <Col xs={6} className="form-wrapper">
                <div className="custom-form-container w-100 px-3">
                    <Form onSubmit={handleDriverSubmit} className="w-100 px-2">
                    <div className='user-logo-image w-100 d-flex align-items-center justify-content-center'>
                            <input type="file" onChange={handleSelectPhoto} className="file-input" accept='image/png, image/jpeg' required />
                            <div className='upload-image-icon'/>
                            <div className='rounded-circle overflow-hidden'>
                                <img src={selectedImageURL ? selectedImageURL : "img/profile.png"} className={selectedImageURL ? "img-fluid" : "img-fluid"} style={{width: "100%",height: "100%"}}></img>
                            </div>
                    </div>
                        <Row>
                            <Col>
                            <CustomInput text="Adınız" icon={null} data={driverDetails} setData={setDriverDetails} name="name" require={true}/>
                                {/* <FloatingLabel controlId="floatingTextarea2" label="Comments">
                                <InputGroup className="mb-4 driver-form-group">
                                    <FormControl as="textarea" className='driver-form-input' placeholder="Leave a comment here" required onChange={(e)=>setDriverDetails({...driverDetails, name: e.target?.value})}/>
                                    <InputGroup.Text className='driver-form-icon' id="basic-addon1">
                                        <FontAwesomeIcon icon={faUser} />
                                    </InputGroup.Text>
                                </InputGroup>
                                    </FloatingLabel> */}
                            </Col>
                            <Col>
                                    <CustomInput text="Soyadınız" icon={null} data={driverDetails} setData={setDriverDetails} name="surname" require={true}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            {/* <CustomInput text="Telefon" icon={faPhone} data={driverDetails} setData={setDriverDetails} name="phone" require={true}/> */}
                            <PhoneInput
                            containerStyle={{marginBottom: "15px"}}
                            containerClass="call-taxi-phone-input-wrapper"
                            inputClass="call-taxi-phone-input"
                            country={'tr'}
                            specialLabel=""
                            placeholder="+90 (5xx) xxx xx xx xx"
                            countryCodeEditable={false}
                            masks={{tr: '(...) ...-..-..'}}
                            onChange={(e:any) => {
                                setDriverDetails({...driverDetails, phone: e})
                            }}
                            value={driverDetails.phone}
                            />  
                                {/* <InputGroup className="mb-4 driver-form-group">
                                    <FormControl className='driver-form-input'placeholder="Telefon" aria-label="Telefon" aria-describedby="basic-addon1" required onChange={(e)=>setDriverDetails({...driverDetails, phone: e.target?.value})}/>
                                    <InputGroup.Text className='driver-form-icon' id="basic-addon1">
                                        <FontAwesomeIcon icon={faPhone} />
                                    </InputGroup.Text>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <CustomInput text="Araç Markası (Zorunlu)" icon={faStar} data={driverDetails} setData={setDriverDetails} name="arac_marka" require={true}/>

                                {/* <InputGroup className="mb-4 driver-form-group">
                                    <FormControl className='driver-form-input'placeholder="Araç Markası (Zorunlu)" aria-label="Araç Markası (Zorunlu)" aria-describedby="basic-addon1" required onChange={(e)=>setDriverDetails({...driverDetails, arac:{...driverDetails.arac, arac_marka:e.target?.value}})}/>
                                    <InputGroup.Text className='driver-form-icon' id="basic-addon1">
                                        <FontAwesomeIcon icon={faStar} />
                                    </InputGroup.Text>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <CustomInput text="Araç Modeli (Zorunlu)" icon={faCar} data={driverDetails} setData={setDriverDetails} name="arac_model" require={true}/>

                                {/* <InputGroup className="mb-4 driver-form-group">
                                    <FormControl className='driver-form-input'placeholder="Araç Modeli (Zorunlu)" aria-label="Araç Modeli (Zorunlu)" aria-describedby="basic-addon1" required onChange={(e)=>setDriverDetails({...driverDetails, arac:{...driverDetails.arac, arac_model:e.target?.value}})}/>
                                    <InputGroup.Text className='driver-form-icon' id="basic-addon1">
                                        <FontAwesomeIcon icon={faCar} />
                                    </InputGroup.Text>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <CustomInput text="Araç Yılı (Zorunlu)" icon={faCalendar} data={driverDetails} setData={setDriverDetails} name ="arac_yil" require={true}/>

                                {/* <InputGroup className="mb-4 driver-form-group">
                                    <FormControl className='driver-form-input'placeholder="Araç Yılı (Zorunlu)" aria-label="Araç Yılı (Zorunlu)" aria-describedby="basic-addon1" required onChange={(e)=>setDriverDetails({...driverDetails, arac:{...driverDetails.arac, arac_yil:e.target?.value}})}/>
                                    <InputGroup.Text className='driver-form-icon' id="basic-addon1">
                                        <FontAwesomeIcon icon={faCalendar} />
                                    </InputGroup.Text>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup className="mb-3 driver-form-group flex-row">
                                    <div className='d-flex flex-row w-100'>
                                        <Select className='w-100 border-none rounded-0' noOptionsMessage={noOptionsMsg} placeholder="İl seçiniz" onChange={(e : SingleValue<{value:string, label:string}>)=>setDriverDetails({...driverDetails, il: e?.value ? e?.value : ''})} styles={{
                                            control: (base:any, state:any) => ({
                                                ...base,
                                                height:'50px',
                                                borderTopLeftRadius: '13px',
                                                borderBottomLeftRadius: '13px',
                                                border:"none",
                                                background:"#F3F6FB",
                                            }),
                                            indicatorSeparator: (base:any, state:any) => ({
                                                ...base,
                                                display: 'none',
                                            }),
                                            input: (base:any, state:any) => ({
                                                ...base,
                                                paddingLeft:"10px",
                                                fontFamily:"Poppins"
                                            }),
                                            placeholder: (base:any, state:any) => ({
                                                ...base,
                                                paddingLeft:"10px",
                                            }),
                                            
                                        }}
                                        options={ilOptions}
                                        />
                                            <InputGroup.Text className='driver-form-icon' id="basic-addon1">
                                                <FontAwesomeIcon icon={faStar} />
                                            </InputGroup.Text>
                                        <FormControl className='fake-input' onChange={undefined} value={driverDetails.il} required/>
                                    </div>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <CustomInput text="Adres" icon={faLocationDot} data={driverDetails} setData={setDriverDetails} name="adres" require={true}/>

                                {/* <InputGroup className="mb-4 driver-form-group">
                                    <FormControl className='driver-form-input'placeholder="Adres" aria-label="Adres" aria-describedby="basic-addon1" required onChange={(e)=>setDriverDetails({...driverDetails, adres: e.target?.value})}/>
                                    <InputGroup.Text className='driver-form-icon' id="basic-addon1">
                                        <FontAwesomeIcon icon={faLocationDot} />
                                    </InputGroup.Text>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <CustomInput text="Referans Bilgileri" icon={faUsers} data={driverDetails} setData={setDriverDetails} name="referans" require={true}/>

                                {/* <InputGroup className="mb-4 driver-form-group">
                                    <FormControl className='driver-form-input' placeholder="Referans Bilgileri" aria-label="Referans Bilgileri" aria-describedby="basic-addon1" onChange={(e)=>setDriverDetails({...driverDetails, referans: e.target?.value})}/>
                                    <InputGroup.Text className='driver-form-icon' id="basic-addon1">
                                        <FontAwesomeIcon icon={faUsers} />
                                    </InputGroup.Text>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex align-items-center justify-content-center"> 
                                <Button variant="secondary" type='submit' style={{background: "#1D1D1F",borderRadius: "21px",width:"144px",height:"45px"}}>
                                    Gönder

                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Col>
        </Row>
        </>
    )
}

export default Drivers;