import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../css/howtouse.css';

function HowToUse() {
    const [state, setState] = useState('');

    useEffect(() => {
        return () => {

        }
    }, []);

    return (
        <Row>
            <Col xxl={12} className="how-to container-padding  w-100 py-5" id='howto'>
                <Row className='how-to-mobile d-flex align-items-center justify-content-center'>
                    <Row>
                        <Col xxl={12} sm={12} className="how-to-title-container-mobile d-flex align-items-center justify-content-center p-2">
                            <h1 className='px-sm-5 px-3'>Nasıl Kullanılır?</h1>
                        </Col>
                    </Row>
                    <Row>                 
                        <Col xxl={12} sm={12} className="how-to-boxes-wrapper-mobile p-0 pt-lg-4 pb-lg-5 pt-0">
                            <Row>
                                <Col xxl={4} lg={4} md={12} className='how-to-box-container-mobile h-100 d-flex flex-row align-items-center justify-content-around p-0'>
                                    <div className='how-to-box-mobile w-100 d-flex align-items-center justify-content-center'>
                                        <div className='how-to-box-inner-mobile w-100 h-100 d-flex flex-column align-items-center'>
                                            <div className='how-to-box-icon-mobile first-icon'>
                                                <img src='./img/howto1.svg' className='object-fit-cover iccc'/>
                                            </div>
                                            <h4 className='text-center pt-lg-5 pt-md-0 pt-2'>
                                                Whatsapp’a Tıklayın
                                            </h4>
                                            <p className='mt-3'>Sol alt köşede yer alan whatsapp ikonuna tıklayın. Sizi sanal asistanımız Heyyo karşılayacak!</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={4} lg={4} md={12} className='how-to-box-container-mobile h-100 d-flex flex-row align-items-center justify-content-around p-0'>
                                    <div className='how-to-box-mobile d-flex align-items-center justify-content-center'>
                                        <div className='how-to-box-inner-mobile w-100 h-100 d-flex flex-column align-items-center'>
                                            <div className='how-to-box-icon-mobile first-icon2'>
                                                <img src='./img/howto2.png' className='object-fit-cover iccc iccc2'/>
                                            </div>
                                            <h4 className='text-center pt-lg-5 pt-md-0 pt-2'>Konumunuzu Bildirin</h4>
                                            <p className=' mt-3'>Hey Taksi’ye konumuzu gönderiniz. Sizin için en uygun ve hızlı yolculuk hazırlanacaktır.</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={4} lg={4} md={12} className='how-to-box-container-mobile h-100 d-flex flex-row align-items-center justify-content-around p-0'>
                                    <div className='how-to-box-mobile d-flex align-items-center justify-content-center'>
                                        <div className='how-to-box-inner-mobile w-100 h-100 d-flex flex-column align-items-center'>
                                            <div className='how-to-box-icon-mobile first-icon'>
                                                <img src='./img/howto3.svg' className='iccc'/>
                                            </div>
                                            <h4 className='text-center pt-lg-5 pt-3'>Yolculuğa Hazırsınız</h4>
                                            <p className=' mt-3'>
                                                Aldığınız teklifinden sonra  aracımız belirtilen süre içerisinde konumuzdan sizi alacaktır.
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>
            </Col>
        </Row>
    )
}

export default HowToUse;