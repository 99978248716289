import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../css/splash.css";
import axios from "axios";
import { logAction, LogTypes } from "../helpers/logActions";
import { useGoogleOneTapLogin } from "react-google-one-tap-login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import TTButton from "./Inputs/TTButton";
import TTLocationInput from "./Inputs/TTLocationInput";
import TextInput from "./Inputs/TextInput";
import { Button } from "react-bootstrap";
import LoginPopup from "../Views/LoginPopup";
import SplashContent from "../Views/SplashContent";
import { useAppSelector } from "../redux/store";
import { PopupTypes } from "../redux/slices/PopupSlice";
import OfferPricePopup from "../Views/OfferPricePopup";
import PhonePopup from "../Views/PhonePopup";

function Splash() {
  const [fromAddress, setFromAddress] = useState<any>("");
  const [toAddress, setToAddress] = useState<any>("");
  const [calculationDetails, setCalculationDetails] = useState<any>({
    km: null,
    estPrice: null,
    calculated: false,
  });

  const [buttonText, setButtonText] = useState<Boolean>(false);
  const [isCalculated, setIsCalculated] = useState<Boolean>(false);
  // https://www.heytaksi.net/?type=ads&utm_campaign=15489589192&utm_source=g_c&utm_medium=cpc&utm_content=579248349559&utm_term=b_hey%20hey%20taksi&adgroupid=132329755842
  const popupData = useAppSelector((state) => state.popupData);

  const handleCalculate = async () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const latlng = { lat: latitude, lng: longitude };
      },
      (error) => {
        alert("Konum izni verilmedi.");
        return;
      }
    );
    if (
      !isCalculated &&
      fromAddress.label?.length > 0 &&
      toAddress.label?.length > 0
    ) {
      let options = (await axios("https://www.turkpark.com.tr:2222/options"))
        .data;
      axios
        .post("https://www.turkpark.com.tr:2222/calcPrice", {
          begin: fromAddress.label,
          end: toAddress.label,
        })
        .then((res: any) => {
          let data: number = res.data;
          if (data === 0) {
            throw Error("Hata oluştu");
          }
          let km: any = (data / 1000).toFixed(1);
          let nFiyat = Math.floor(km * options.price);
          let nFiyat1 = Math.floor(km * options.price * 0.92);
          let nFiyat2 = Math.floor(km * options.price * 1.02);
          setCalculationDetails({
            km: km,
            estPrice: nFiyat1 + "-" + nFiyat2,
            calculated: true,
          });
          setIsCalculated(true);
          logAction(LogTypes.calculate);
        })
        .catch(() => {
          alert("Lütfen Bilgilerinizi Kontrol Ediniz.");
        });
    } else if (fromAddress.label?.length > 0) {
      let textParameter =
        "Merhaba, Hey Taksi Çağırmak İstiyorum. Adres: " + fromAddress.label;
      window.open(
        "https://api.whatsapp.com/send/?phone=905516879051&text=" +
          textParameter +
          "&app_absent=0"
      );
      logAction(LogTypes.callTaxi);
    } else {
      alert("Lütfen kalkış yerinizi giriniz.");
    }
  };

  useEffect(() => {
    setIsCalculated(false);
  }, [fromAddress, toAddress]);

  console.log(popupData);
  return (
    <Row id="splash">
      <Col xxl={12} className="splash container-padding">
        <Row className="p-0 h-100">
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={12}
            sm={12}
            className="d-flex align-items-lg-start align-items-center flex-column px-4 bg-red"
          >
            <div className="brand-title-container d-none d-md-flex">
              <h2 className="brand-title">
                hey
                <span>taksi</span>
                transfer
              </h2>
            </div>

            <SplashContent />
          </Col>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={12}
            sm={12}
            className="right-side-wrapper d-none d-lg-flex"
          >
            <div className="splash-image-container">
              <img src="/img/splash.png" alt="Splash" />
            </div>
          </Col>
        </Row>
      </Col>
      {popupData.popup === PopupTypes.loginPopup && <LoginPopup />}
      {popupData.popup === PopupTypes.offerPrice && <OfferPricePopup />}
      {popupData.popup === PopupTypes.phonePopup && <PhonePopup />}
    </Row>
  );
}

export default Splash;
