import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActionMeta } from "react-select";

export enum SplashContentTypes {
  CallTaxi = "call-taxi",
  CalculatePrice = "calculate-price",
}

const initialState: SplashContentTypes = SplashContentTypes.CallTaxi;
const { actions, reducer } = createSlice({
  name: "splashData",
  initialState: initialState as SplashContentTypes,
  reducers: {
    setSplashScreen(state, action: PayloadAction<SplashContentTypes>) {
      state = action.payload;
      return state;
    },
  },
});

export { actions, reducer };
