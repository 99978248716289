import "./App.css";
import Website from "./Views/Website";
import "bootstrap/dist/css/bootstrap.css";
import SocketController from "./controller/SocketController";
import { useEffect } from "react";
import { Provider } from "react-redux";
import store, { useAppDispatch, userActions } from "./redux/store";
import { useGoogleOneTapLogin } from "react-google-one-tap-login";
import { IGoogleEndPointResponse } from "react-google-one-tap-login/dist/types/types";
import { UserData } from "./redux/slices/UserSlice";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Website />} />
          </Routes>
        </BrowserRouter>
        <SocketController />
      </Provider>
    </>
  );
}

export default App;
