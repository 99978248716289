import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../css/mobilemenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
interface MobileMenuProps {
  setMobileMenu: any;
}

function MobileMenu(props: MobileMenuProps) {
  return (
    <div className="mobile-menu-wrapper">
      <div className="d-flex justify-content-between align-items-center ps-2 pe-3">
        <h2 className="d-flex">
          hey<span>taksi</span>transfer
        </h2>
        <FontAwesomeIcon
          icon={faClose}
          size="2x"
          onClick={() => props.setMobileMenu(false)}
        ></FontAwesomeIcon>
      </div>
      <div className="mobile-menu-items-container">
        <a href="#splash" onClick={() => props.setMobileMenu(false)}>
          <div className={`mobile-menu-item m-0`}>Anasayfa</div>
        </a>
        <a href="#about" onClick={() => props.setMobileMenu(false)}>
          <div className="mobile-menu-item m-0">Hakkımızda</div>
        </a>
        <a href="#services" onClick={() => props.setMobileMenu(false)}>
          <div className="mobile-menu-item m-0">Servislerimiz</div>
        </a>
        <a href="#drivers" onClick={() => props.setMobileMenu(false)}>
          <div className="mobile-menu-item m-0">Sürücülerimiz</div>
        </a>
        <a href="#driverform" onClick={() => props.setMobileMenu(false)}>
          <div className="mobile-menu-item m-0">Sürücü Başvurusu</div>
        </a>
      </div>
    </div>
  );
}

export default MobileMenu;
