import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState, useEffect } from "react";
import "./tt-location.css";
const apiKey = "AIzaSyAvSIFGIo-hmpQwRS-SKcUkqAepbT3LzVA";

interface IResults {
  text: string;
}

interface TTLocationInputProps {
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelect?: (value: string) => void;
  ref?: React.RefObject<HTMLInputElement>;
  value?: string;
  className?: string;
}

function TTLocationInput(props: TTLocationInputProps) {
  const { className, placeholder, onChange, onSelect, ref, value } = props;
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>(value || "");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (e.target.value.length > 2) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/place/autocomplete/json?components=country:tr&input=${e.target.value}&language=tr&types=geocode&key=${apiKey}`
        )
        .then((res) => {
          console.log(res.data);
          setResults(res.data.predictions);
        });
    } else {
      setSearchResults([]);
    }
    if (onChange) onChange(e);
  };

  const setResults = (results: any) => {
    const adresses: string[] = [];
    results.map((result: any) => {
      const formatted =
        result.structured_formatting.main_text +
        ", " +
        result.structured_formatting.secondary_text;
      adresses.push(formatted);
    });
    setSearchResults(adresses);
  };

  console.log(searchResults);
  return (
    <div className="tt-location-input-container">
      <input
        ref={ref}
        className={"tt-location-input " + className}
        type="text"
        placeholder={placeholder}
        onChange={handleInputChange}
        value={inputValue}
      />
      <div className="search-results">
        {searchResults.length < 1 && (
          <p className="text-center m-0 d-none">Sonuç bulunamadı</p>
        )}
        <ul>
          {searchResults.map((result: string, index) => (
            <li
              onClick={() => {
                setInputValue(result);
                setSearchResults([]);
                if (onSelect) onSelect(result);
              }}
              key={index}
            >
              <span className="pe-2">
                <FontAwesomeIcon icon={faLocationDot} />
              </span>
              {result}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default TTLocationInput;
