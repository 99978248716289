import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../css/about.css';

function About() {

    return (
        <>
        <Row className='about d-none d-md-flex' id='about'>
            <Col xs={12} className="about-mobile flex-column container-padding py-5 px-4">
                <Row className=''>
                    <Col xs={7} className="about-title-container-mobile">
                        <h1 className='d-inline p-0 desktop-h1 pb-'>HAKKIMIZDA</h1>
                        <p className='pt-5'>2021 başından bu yana 'HEY TAKSİ & TRANSFER’ olarak hizmet vermekteyiz. Ekibimiz seçkin ve tecrübeli kişilerden oluşup, araçlarımız çok çeşitli ve her ihtiyaca hitap edecek modellerden oluşmaktadır.</p>
                    </Col>
                    <Col xs={5} className="about-picture-mobile-container d-flex align-items-center justify-content-center">
                        {/* <div className='clip-wrapper'>
                            <div className='first-clip'></div>
                            <div className='second-clip'></div>
                            <div className='third-clip'></div>
                        </div> */}
                        <div className='about-picture-mobile w-100 d-flex' ></div>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row className="about-mobile d-flex d-md-none">
            <Col xs={12} className="about-mobile flex-column container-padding py-5 px-4">
            <Col xs={12} className="about-title-container-mobile p-3">
                    <h1 className='text-center' >HAKKIMIZDA</h1>
                </Col>
                <Col xs={12} className="about-picture-mobile-container">
                    {/* <div className='clip-wrapper'>
                        <div className='first-clip'></div>
                        <div className='second-clip'></div>
                        <div className='third-clip'></div>
                    </div> */}
                    <div className='about-picture-mobile w-100 d-flex' ></div>


                </Col>
                <Col xs={12} className="about-description-mobile-container pt-4">
                    <p>2021 başından bu yana 'HEY TAKSİ & TRANSFER’ olarak hizmet vermekteyiz. Ekibimiz seçkin ve tecrübeli kişilerden oluşup, araçlarımız çok çeşitli ve her ihtiyaca hitap edecek modellerden oluşmaktadır.</p>
                </Col>
            </Col>
        </Row>
        </>
    )
}

export default About;