import axios from "axios";
import { useContext, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap"
import { Button } from 'react-bootstrap';
import PhoneInput from "react-phone-input-2";
import { SocketContext } from "../../controller/Context";

interface ModalInputProps {
    setData: (data: any) => void;
    data: any;
}

const CallTaxi: React.FC<ModalInputProps> = ({data,setData})=>{
    const [phone, setPhone] = useState<string>('');
    const [send, setSend] = useState<string>("");
    const [info, setInfo] = useState<any>([])
    const client = useContext(SocketContext);
    const handleClick = async() => {
        if(send==="success"){
            setData(false)
            return
        }
        setSend("")
        navigator.geolocation.getCurrentPosition(position => {
            const {latitude, longitude} = position.coords;
            const latlng = {lat: latitude, lng: longitude};
            setSend("sending")
            axios.post("https://www.turkpark.com.tr:2222/calculateDistance", {
                user:{ degreesLatitude: latitude, degreesLongitude: longitude }
            }, { timeout: 15000 })
            .then(res => {
                setInfo(res.data)
                setSend("success")

            axios.post('https://www.turkpark.com.tr:2222/customer/location', {
                        name: `web-${phone.replace("+","")}`,
                        phone: phone.replace("+",""),
                        location: { degreesLatitude: latitude, degreesLongitude: longitude },
                    }).then((res) => {

                    })
            .catch(err => {
                setSend("error")
            })

            axios.post('https://www.turkpark.com.tr:2222/customer/instLocation', {
            name: `web-${phone.replace("+","")}`,
            phone: phone.replace("+",""),
            location: { degreesLatitude: latitude, degreesLongitude: longitude },

            }).then((res) => {
                setSend("success")
                axios.post('https://www.turkpark.com.tr:2222/notification/add', { name:`web-${phone.replace("+","")}`,phone:phone.replace("+",""), type: 'Konum' })
                client.emit("newNotification", { msg: { degreesLatitude: latitude, degreesLongitude: longitude }, type: 'Konum' })

                client.emit("customerLocation", {});
            })
        })
        .catch(err => {
            setSend("error")

        })
        }, (error) => {
            alert("Konum izni verilmedi.");
        });
    };
    return(
        <>
            <Modal
            size="sm"
            aria-labelledby="contained-modal-title"
            centered
            show={data} 
            onHide={()=>{setData(false)}}
            style={{borderRadius: '20px !important'}}
            dialogClassName="border-radius-2"
            >
            <Modal.Header closeButton style={{borderRadius: '20px !important',borderBottom:"none"}}>
            </Modal.Header>
            <Modal.Body style={{borderRadius: '20px !important',paddingTop:0}}>
                <Form>
                    <div className='form-container' >
                        <p className="custom-form-text" style={{marginBottom:"50px !important"}}>
                            {"Lütfen telefon numaranızı giriniz."}
                        </p>

                        <PhoneInput
                            containerClass="call-taxi-phone-input-wrapper"
                            inputClass="call-taxi-phone-input"
                            country={'tr'}
                            specialLabel=""
                            placeholder="+90 (5xx) xxx xx xx xx"
                            countryCodeEditable={false}
                            masks={{tr: '(...) ...-..-..'}}
                            onChange={(phone) => {
                                setPhone(phone);
                            }}
                            value={phone}
                            />      
                    </div>
                {
                    <>
                        {   
                            (() => {
                                switch (send) {
                                    case "success":
                                        return(
                                            <div style={{display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center"}}>
                                                <span style={{marginRight:"20px"}}>{`Hey Taksi belirttiğiniz adrese yaklaşık ${info[0] && (info[0].duration / 60).toFixed(0)} dk içinde gelecektir.\n\nSürücümüz 850 304 03 20 numarasından sizinle iletişime geçecektir.\nTeşekkür ederiz.`}</span>
                                                {/* <CheckCircleIcon style={{color:"green"}}/> */}
                                            </div>
                                        )
                                    case "error":
                                        return(
                                            <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"50px"}}>
                                                <span style={{marginRight:"20px"}}>{"Şu anda size yakın bir Hey Taksi mevcut değil."}</span>
                                            </div>
                                        )
                                    default:
                                        return(
                                            <></>
                                        )
                                }
                            })()
                        }
                    </>
                }

                <div style={{display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center",marginTop:"20px"}}>
                    <Button 
                        variant="secondary" 
                        style={{background: "#1D1D1F",borderRadius: "21px",width: "auto",
                        height: "45px"}}
                        onClick={handleClick}
                        >
                            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>  
                            {   
                                (() => {
                                    switch (send) {
                                        case "sending":
                                            return (
                                                <>
                                                    <span >{"Gönderiliyor"}</span>
                                                    <Spinner animation="border" variant="light" style={{marginLeft:"20px"}}/>
                                                </>
                                            )
                                        case "success":
                                            return(
                                                    <span >{"Başarıyla Gönderildi"}</span>
                                            )
                                        default:
                                            return ( 
                                                <div style={{width:"144px"}}>{"Gönder"}</div>
                                                )
                                        }
                                })()
                            }
                            </div>

                    </Button>

                </div>
                        </Form>
            </Modal.Body>
            </Modal>
        </>
    )
}
export default CallTaxi