import React, { useMemo, useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "../css/main.css";
import Navbar from "../components/Navbar";
import Splash from "../components/Splash";
import About from "../components/About";
import HowToUse from "../components/HowToUse";
import Services from "../components/Services";
import Drivers from "../components/Drivers";
import DriverForm from "../components/DriverForm";
import MobileMenu from "../components/MobileMenu";
import Footer from "../components/Footer";
import FloatingButtons from "../components/FloatingButtons";
import Banner from "../components/Banner";
import { logAction, LogTypes } from "../helpers/logActions";
import { popupActions, useAppDispatch, userActions } from "../redux/store";
import { IGoogleEndPointResponse } from "react-google-one-tap-login/dist/types/types";
import { useGoogleOneTapLogin } from "react-google-one-tap-login";
import { UserData } from "../redux/slices/UserSlice";
import { PopupTypes } from "../redux/slices/PopupSlice";
import axios from "axios";
function Website() {
  const [showMobileMenu, setShowMobileMenu] = useState<Boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const urlParams = new URL(window.location.href).searchParams.get("type");
    const keyword = new URL(window.location.href).searchParams.get("keyword");

    if (urlParams === "ads") {
      logAction(LogTypes.keyword, keyword);
    } else logAction(LogTypes.visit);
  }, []);

  const handleGoogleLogin = (e: IGoogleEndPointResponse) => {
    let userData: UserData = {
      isLoggedIn: true,
      fullname: e.name,
      picture: e.picture,
      email: e.email,
      phone: undefined,
    };
    dispatch(userActions.loginUser(userData));
    checkUser(userData)
      .then((data: any) => {
        userData.phone = data.phone;
      })
      .catch((err) => {
        console.log(err);
        dispatch(popupActions.setPopup(PopupTypes.phonePopup));
      });
  };

  const checkUser = async (userData: UserData) => {
    const data = await axios.post(
      "http://192.168.0.35:1111/webUsers/checkUser",
      {
        email: userData.email,
      }
    );

    if (data.status === 200) return Promise.resolve(data.data);
    return Promise.reject("rejected");
  };

  useGoogleOneTapLogin({
    onError: (error) => console.log(error),
    onSuccess: handleGoogleLogin,
    googleAccountConfigs: {
      client_id:
        "924697509051-ibdue00rj18tm3s0beg27f89mc7ugeoi.apps.googleusercontent.com",
    },
    disableCancelOnUnmount: true,
    disabled: false,
  });

  return (
    <>
      <Navbar setMobileMenu={setShowMobileMenu} />
      <Container
        id="website-container"
        fluid={true}
        style={{ paddingLeft: "0px", paddingRight: "0px" }}
      >
        <Splash />
        <Banner />
        <HowToUse />
        <About />
        <Services />
        <Drivers />
        <DriverForm />
        <Footer />
        {showMobileMenu && <MobileMenu setMobileMenu={setShowMobileMenu} />}
      </Container>
      <FloatingButtons />
    </>
  );
}

export default Website;
