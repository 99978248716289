import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import TextInput from "../components/Inputs/TextInput";
import { PopupTypes } from "../redux/slices/PopupSlice";
import { UserData } from "../redux/slices/UserSlice";
import {
  popupActions,
  useAppDispatch,
  useAppSelector,
  userActions,
} from "../redux/store";

function OfferPricePopup() {
  const dispatch = useAppDispatch();
  const popupData = useAppSelector((state) => state.popupData);
  const [price, setPrice] = useState("");
  const [error, setError] = useState(false);

  const checkForm = () => {
    if (price.length > 0) {
      return true;
    }
    return false;
  };

  const handleSubmit = () => {};
  return (
    <div id="login-popup">
      <div className="login-wrapper">
        <IoClose
          className="close-icon"
          onClick={() => dispatch(popupActions.setPopup(PopupTypes.none))}
        />
        <div className="login-container d-flex flex-column align-items-center">
          <div className="login-header">
            <h2 className="text-center">Teklif Verin</h2>
          </div>
          <div className="login-form d-flex flex-column gap-3 w-100 pt-4">
            <div className="name">
              <TextInput
                mask="₺ 9999"
                placeholder="Teklif Fiyatı"
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
          </div>
          <div className="login-form-error">
            {error && <p className="text-left">Lütfen alanları doldurunuz.</p>}
          </div>
          <div className="login-button-container pt-3">
            <div className="login-submit-button">
              <div className="submit-button" onClick={handleSubmit}>
                Devam Et
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfferPricePopup;
