import axios from "axios";

const apiKey = "AIzaSyAvSIFGIo-hmpQwRS-SKcUkqAepbT3LzVA";

export const handleCalculation = async (
  fromAddress: string,
  toAddress: string
) => {
  if (fromAddress === "" || toAddress === "") {
    alert("Lütfen adresleri doldurunuz.");
    return;
  }
  let options = (await axios("https://www.turkpark.com.tr:2222/options")).data;

  console.log(fromAddress, toAddress);
  const results = axios
    .post("https://www.turkpark.com.tr:2222/calcPrice", {
      begin: fromAddress,
      end: toAddress,
    })
    .then((res) => {
      let km: any = (res.data / 1000).toFixed(0);
      let nFiyat = Math.floor(km * options.price);
      let nFiyat1 = Math.floor(km * options.price * 0.92);
      let nFiyat2 = Math.floor(km * options.price * 1.02);
      return {
        km: km,
        estPrice: nFiyat,
        calculated: true,
      };
    });
  return await results;
};

export const reverseGeocode = async (lat: number, lng: number) => {
  const result = await axios.post(
    "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      lat +
      "," +
      lng +
      "&key=" +
      apiKey
  );

  return result.data.results[0].formatted_address;
};
