import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActionMeta } from "react-select";

export interface UserData {
  email?: string;
  fullname: string;
  phone?: string;
  isLoggedIn: boolean;
  picture?: string;
}

const userData = localStorage.getItem("tt-userData") || undefined;
const initialState: UserData = userData
  ? JSON.parse(userData)
  : {
      isLoggedIn: false,
    };

const { actions, reducer } = createSlice({
  name: "userData",
  initialState: initialState,
  reducers: {
    loginUser(state, action: PayloadAction<UserData>) {
      state = action.payload;
      return state;
    },
    setPhone(state, action: PayloadAction<string>) {
      state.phone = action.payload;
      return state;
    },
    setLoggedIn(state, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload;
      return state;
    },
  },
});

export { actions, reducer };
