import React, { useState, useEffect } from "react";
import { IoRefresh } from "react-icons/io5";
import { PopupTypes } from "../redux/slices/PopupSlice";
import { popupActions, useAppDispatch, useAppSelector } from "../redux/store";
import "./css/calculation-results.css";

interface CalculationResultsProps {
  km: string | number;
  price: string | number;
  onReset: () => void;
}
function CalculationResults(props: CalculationResultsProps) {
  const { km, price, onReset } = props;
  const userData = useAppSelector((state) => state.userData);
  const dispatch = useAppDispatch();

  const handleAcceptPriceClick = () => {
    if (!userData.isLoggedIn) {
      dispatch(popupActions.setPopup(PopupTypes.loginPopup));
    }
  };

  const handleRejectPriceClick = () => {
    dispatch(popupActions.setPopup(PopupTypes.offerPrice));
  };
  return (
    <div className="calculated-price-wrapper">
      <div className="calculated-price-container">
        <div className="calculated-price-km">
          <h2>MESAFE*</h2>
          <h2>{km} KM</h2>
        </div>
        <div className="calculated-price-value">
          <h2>ÜCRET*</h2>
          <h2>{price} ₺</h2>
        </div>
      </div>
      <div className="calculated-price-buttons-wrapper">
        <div className="calculated-price-buttons-container">
          <div className="calculated-price-button retry" onClick={onReset}>
            <IoRefresh />
          </div>
          <div
            className="calculated-price-button reject"
            onClick={handleRejectPriceClick}
          >
            Reddet
          </div>
          <div
            className="calculated-price-button accept"
            onClick={handleAcceptPriceClick}
          >
            Kabul Et
          </div>
        </div>
      </div>
      <div className="calculated-price-note">*Tahmini</div>
    </div>
  );
}

export default CalculationResults;
