import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faClose, faPhone } from "@fortawesome/free-solid-svg-icons";
import "../css/floatingbuttons.css";
import { logAction, LogTypes } from "../helpers/logActions";
import CallTaxi from "./modal/CallTaxi";
import {
  IoLogoWhatsapp,
  IoCallOutline,
  IoChatboxEllipsesOutline,
  IoPersonOutline,
} from "react-icons/io5";
interface MobileMenuProps {
  setMobileMenu: any;
}

function FloatingButtons() {
  const [showScrollToTop, setShowScrollToTop] = useState<Boolean>(false);
  const [showCallTaxi, setShowCallTaxi] = useState<Boolean>(false);
  const ScrollCheck = () => {
    if (window.scrollY > 400) {
      setShowScrollToTop(true);
    } else {
      setShowScrollToTop(false);
    }
  };

  const BeforeUnload = () => {
    logAction(LogTypes.closed);
  };

  useEffect(() => {
    window.addEventListener("scroll", ScrollCheck);
    window.addEventListener("beforeunload", BeforeUnload);
    return () => {
      window.removeEventListener("scroll", ScrollCheck);
      window.removeEventListener("beforeunload", BeforeUnload);
    };
  }, []);

  const handleClick = (href: any, type: any, target: string) => {
    logAction(type);
    window.open(href, target, "noopener, noreferrer");
  };

  const CallTaxiClick = () => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser");
    } else {
      navigator.geolocation.getCurrentPosition(
        () => {
          setShowCallTaxi(true);
        },
        () => {
          alert("Konum izni verilmedi.");
        }
      );
      logAction(LogTypes.callTaxi);
    }
  };

  const handleChatClick = () => {
    window.tidioChatApi.display(true);
    window.tidioChatApi.open();
  };

  return (
    <>
      <div className="floating-bottom-nav-wrapper d-md-none d-flex">
        <div className="floating-bottom-nav-container">
          <div className="nav-item">
            <IoLogoWhatsapp />
          </div>
          <div className="nav-item">
            <IoCallOutline />
          </div>
          <div className="nav-item" onClick={handleChatClick}>
            <IoChatboxEllipsesOutline />
          </div>
          <div className="nav-item">
            <IoPersonOutline />
          </div>
        </div>
      </div>
      {showScrollToTop && (
        <div
          className="scroll-to-top d-flex align-items-center justify-content-center"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <a>
            <FontAwesomeIcon
              icon={faArrowUp}
              color="#444"
              className="scroll-to-top-icon"
            />
          </a>
        </div>
      )}
      <CallTaxi data={showCallTaxi} setData={setShowCallTaxi} />
    </>

    /*
        <a href="#driverform">
            <div className='left-float d-flex flex-column'>
                <div className='driver-icon h-100'></div>
            </div>
        </a>
        */
  );
}

export default FloatingButtons;
