import { faArrowLeft, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import TTLocationInput from "../components/Inputs/TTLocationInput";
import { SplashContentTypes } from "../redux/slices/SplashSlice";
import { splashActions, useAppDispatch } from "../redux/store";
import CalculationResults from "./CalculationResults";
import "./css/calculateprice.css";

function CalculatePrice() {
  const [fromAddress, setFromAddress] = useState("");
  const [toAddress, setToAddress] = useState("");
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");

  const dispatch = useAppDispatch();

  const calcInitialState = {
    km: 0,
    estPrice: "0",
    calculated: false,
  };
  const [calculationDetails, setCalculationDetails] =
    useState(calcInitialState);

  const handleCalculation = async () => {
    if (fromAddress === "" || toAddress === "") {
      alert("Lütfen adresleri doldurunuz.");
      return;
    }
    let options = (await axios("https://www.turkpark.com.tr:2222/options"))
      .data;

    console.log(fromAddress, toAddress);
    axios
      .post("https://www.turkpark.com.tr:2222/calcPrice", {
        begin: fromAddress,
        end: toAddress,
      })
      .then((res) => {
        let km: any = (res.data / 1000).toFixed(0);
        let nFiyat = Math.floor(km * options.price);
        let nFiyat1 = Math.floor(km * options.price * 0.92);
        let nFiyat2 = Math.floor(km * options.price * 1.02);
        setCalculationDetails({
          km: km,
          estPrice: nFiyat.toString(),
          calculated: true,
        });
      });
  };

  const handleResetCalculation = () => {
    setFromValue("");
    setFromAddress("");
    setToValue("");
    setToAddress("");
    setCalculationDetails(calcInitialState);
  };

  const handleBackClick = () => {
    dispatch(splashActions.setSplashScreen(SplashContentTypes.CallTaxi));
  };

  return (
    <>
      <div className="calculate-price-wrapper">
        <div className="calculate-price-container pt-3">
          <div className="from-input-container">
            <TTLocationInput
              onSelect={(val) => {
                setFromAddress(val);
                setFromValue(val);
              }}
              placeholder="Kalkış Konumunuzu Giriniz"
              value={fromValue}
              onChange={(e) => setFromValue(e.target.value)}
            />
          </div>
          <div className="destination-input-container">
            <TTLocationInput
              onSelect={(val) => {
                setToAddress(val);
                setToValue(val);
              }}
              placeholder="Varış Konumunuzu Giriniz"
              value={toValue}
              onChange={(e) => {
                setToValue(e.target.value);
              }}
            />
          </div>
          {calculationDetails.calculated && (
            <CalculationResults
              km={calculationDetails.km}
              price={calculationDetails.estPrice}
              onReset={handleResetCalculation}
            />
          )}
          <div className="calculate-price-button-container">
            <div className="calculate-price-button" onClick={handleBackClick}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
            <div className="calculate-price-button" onClick={handleCalculation}>
              {calculationDetails.calculated ? "Taksi Çağır" : "Hesapla"}
            </div>
            <div
              className="calculate-price-button"
              onClick={handleResetCalculation}
            >
              <FontAwesomeIcon icon={faRefresh} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CalculatePrice;
