import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";

interface PlacesInputProps {
    text: string;
    icon: any;
    setData: (data: any) => void;
    data: any;
    name:String
    require:boolean
}


const CustomInput: React.FC<PlacesInputProps> = ({name,text,icon,data,require,setData}) => {
    const [isFocus, setIsFocus] = useState(false);
    const ref = useRef<HTMLInputElement>(null);
    const handleClick = () =>{
        setIsFocus(true);
        setTimeout(()=>{ref.current?.focus()},10);
    }
    useEffect(() => {
        if(!ref) return
        ref.current?.focus();
    },[ref]);

    const handleChange=(e:any)=>{
        setData({...data, [`${e.target?.name}`]: e.target?.value})
    }

    const handleBlur = (e:any) => {
        if(e.target?.value.length === 0) return setIsFocus(false);
    }
    return(
        <>
            <div className="custom-container" onClick={()=>{handleClick()}}>
                <div className="custom-input-wrapper" style={{paddingBottom:isFocus?"8px":""}}>
                    <label className={`custom-input-label${isFocus?"-focus":""}`}>{text}</label>
                    {isFocus &&  <input type='text' 
                        name={`${name}`} 
                        className="custom-input" 
                        ref={ref} 
                        onBlur={handleBlur} 
                        value={data[`${name}`]} 
                        onChange={handleChange}
                        required={require}
                        />}
                    
                </div>
                {icon && <FontAwesomeIcon icon={icon} />}
                
            </div>
        </>
    )
}

export default CustomInput;