import axios from "axios";
import React, { useState, useEffect } from "react";
import TTButton from "../components/Inputs/TTButton";
import { SplashContentTypes } from "../redux/slices/SplashSlice";
import { useAppSelector } from "../redux/store";
import CalculatePrice from "./CalculatePrice";
import CallTaxi from "./CallTaxi";

function SplashContent() {
  const [content, setContent] = useState(<CallTaxi />);
  const [kmPrice, setKmPrice] = useState(0);
  const splashContent = useAppSelector((state) => state.splashData);

  useEffect(() => {
    if (splashContent === SplashContentTypes.CallTaxi) {
      setContent(<CallTaxi />);
    } else if (splashContent === SplashContentTypes.CalculatePrice) {
      setContent(<CalculatePrice />);
    }
  }, [splashContent]);

  useEffect(() => {
    axios.get("https://www.turkpark.com.tr:2222/options").then((res) => {
      setKmPrice(res.data.price);
    });
  });

  return (
    <>
      <div className="splash-title-container">
        <h1 className="splash-title">
          {kmPrice} ₺/km’den başlayan fiyatlar <br /> ile güvenli yolculuk*
        </h1>
      </div>
      {content}
    </>
  );
}

export default SplashContent;
