import React, { useState, useEffect } from "react";
import ReactInputMask from "react-input-mask";
import "../Inputs/inputs.css";

interface TextInput {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
  mask?: string;
}

function TextInput(props: TextInput) {
  const { onChange, placeholder, type, mask } = props;
  return (
    <div className="text-input">
      <ReactInputMask
        type={type}
        mask={mask || ""}
        maskChar=""
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
}

export default TextInput;
