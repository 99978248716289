import axios from "axios";
import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import TextInput from "../components/Inputs/TextInput";
import { PopupTypes } from "../redux/slices/PopupSlice";
import { UserData } from "../redux/slices/UserSlice";
import {
  popupActions,
  useAppDispatch,
  useAppSelector,
  userActions,
} from "../redux/store";

function PhonePopup() {
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.userData);
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(false);

  const checkForm = () => {
    if (phone.length > 0) {
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    const check = checkForm();
    if (check) {
      axios
        .post("http://192.168.0.35:1111/webUsers", {
          fullname: userData.fullname,
          email: userData.email,
          phone: phone,
        })
        .then((data) => {
          dispatch(userActions.setPhone(phone));
          console.log("phone set", phone);
          dispatch(userActions.setLoggedIn(true));
          dispatch(popupActions.setPopup(PopupTypes.none));
        })
        .catch(() => {
          alert("Kullanıcı oluşturulamadı.");
        });
      setError(false);
    } else {
      setError(true);
    }
  };

  console.log("giriş ", userData, phone);
  return (
    <div id="login-popup">
      <div className="login-wrapper">
        <IoClose
          className="close-icon"
          onClick={() => dispatch(popupActions.setPopup(PopupTypes.none))}
        />
        <div className="login-container d-flex flex-column align-items-center">
          <div className="login-header">
            <h2 className="text-center">Telefon Numarası</h2>
          </div>
          <div className="login-form d-flex flex-column gap-3 w-100 pt-4">
            <div className="phone">
              <TextInput
                mask="0 599 999 9999"
                placeholder="Telefon Numaranız"
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="login-form-error">
            {error && <p className="text-left">Lütfen alanları doldurunuz.</p>}
          </div>
          <p className="m-0">
            Hizmetlerimizi kullanabilmeniz için telefon numarası ile devam
            etmeniz gerekmektedir.
          </p>
          <div className="login-button-container pt-3">
            <div className="login-submit-button">
              <div className="submit-button" onClick={handleSubmit}>
                Devam Et
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhonePopup;
