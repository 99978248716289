import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../css/footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <>
      <div id="footer" className="d-block d-lg-none pt-4">
        <Row className="p-0 footer">
          <div className="footer" style={{ padding: "15px 20px " }}>
            <div className="logo-wrapper d-flex">
              <a href="https://heytaksi.net">
                <div className="taksi-logo" />
              </a>
            </div>
          </div>
        </Row>
        <Row className="footer" style={{ padding: "15px 10px " }}>
          <Col className="footer pt-3 d-flex flex-column info">
            <h3 style={{ color: "white" }}>Menu</h3>
            <a
              href="#splash"
              style={{ marginBottom: "15px", marginTop: "15px" }}
            >
              Ana Sayfa
            </a>
            <a href="#about" style={{ marginBottom: "15px" }}>
              Hakkımızda
            </a>
            <a href="#services" style={{ marginBottom: "15px" }}>
              Sürücülerimiz
            </a>
            <a href="#driverform" style={{ marginBottom: "15px" }}>
              Başvuru Formu
            </a>
          </Col>
          <Col className="footer pt-3">
            <div className="info d-flex flex-column justify-items-end align-items-end ">
              <h4>İletişim</h4>
              <a
                href="mailto:info@heytaksi.net"
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                info@heytaksi.net
              </a>
              <a href="tel:+908503040320">0850 304 0 320</a>
            </div>
          </Col>
          <div className="footer-divider-wrapper">
            <div className="footer-divider"></div>
            <span className="footer-copyright">
              {"Copyright 2022 Hey Taksi- Tüm Hakları Saklıdır"}
            </span>
          </div>
        </Row>
      </div>
      <Row className="d-none d-lg-flex">
        <Col
          xs={12}
          className="footer-desktop pt-5 d-flex flex-column align-items-center"
        >
          <div className="footer-logos d-flex flex-row align-items-center justify-content-center">
            <img src="img/logo-white.png" height="50" className="m-2" />
            <img src="img/hey.svg" className="m-2" />
          </div>
          <div className="footer-link d-flex align-items-center justify-content-center">
            <a href="#root">Ana Sayfa</a>
            <a href="#howto">Nasıl Kullanılır?</a>
            <a href="#about">Hakkımızda</a>
            <a href="#drivers">Sürücülerimiz</a>
            <a href="#driverform">Başvuru Formu</a>
          </div>
          <div className="footer-info d-flex align-items-center justify-content-center">
            <a href="mailto:info@heytaksi.net">info@heytaksi.net</a>
            <a href="tel:+908503040320">0850 304 0 320</a>
          </div>
          <div className="copyright mt-4 text-center pt-3 pb-4">
            <span>Copyright 2020 Hey Taksi - Tüm Hakları Saklıdır</span>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Footer;
