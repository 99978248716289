import React, { useState, useEffect } from "react";
import {
  faArrowLeft,
  faArrowRight,
  faBackward,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TTButton from "../components/Inputs/TTButton";
import { SplashContentTypes } from "../redux/slices/SplashSlice";
import {
  popupActions,
  splashActions,
  useAppDispatch,
  useAppSelector,
} from "../redux/store";
import CalculationResults from "./CalculationResults";
import "./css/call-taxi.css";
import { PopupTypes } from "../redux/slices/PopupSlice";
import { useNavigate } from "react-router-dom";

function CallTaxi() {
  const [isOpen, setIsOpen] = useState(false);
  const [calculationResults, setCalculationResults] = useState<any>(false);
  const userData = useAppSelector((state) => state.userData);
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (calculationResults) {
      if (userData.isLoggedIn) {
        console.log("logged in");
      } else {
        console.log(PopupTypes.loginPopup);
        dispatch(popupActions.setPopup(PopupTypes.loginPopup));
      }
      console.log(localStorage.getItem("pastaa") || "ege");
    } else {
      dispatch(
        splashActions.setSplashScreen(SplashContentTypes.CalculatePrice)
      );
    }
  };

  const handleTTSubmit = (value: any) => {
    setCalculationResults(value);
  };

  const handleBack = () => {
    setCalculationResults(false);
  };
  return (
    <>
      <TTButton onSubmit={handleTTSubmit} setOpen={setIsOpen} />
      {calculationResults && (
        <div className="call-taxi-calculation-results-wrapper">
          <CalculationResults
            km={calculationResults.km}
            price={calculationResults.estPrice}
            onReset={handleBack}
          />
        </div>
      )}
      <div className="splash-price-info-container">
        <div className="price-info-button-container justify-content-lg-start justify-content-center">
          {isOpen && (
            <div className="price-reset-button" onClick={() => handleBack()}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
          )}
          <div className="price-info-button" onClick={handleClick}>
            <p className="m-0">
              {calculationResults ? "Taksi Çağır" : "Tahmini Fiyat Al"}
            </p>
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </div>
        <div className="price-info-text-container">
          <p>*Telefon numaranız gizlenmektedir.</p>
        </div>
      </div>
    </>
  );
}

export default CallTaxi;
