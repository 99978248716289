import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../css/about.css';

function Drivers() {

    return (
        <div id="drivers" >
            <Row className='d-flex d-md-none'>
                <Col xs={12} className="about-mobile drivers-mobile flex-column container-padding py-5">
                    <Col xs={12} className="about-title-container-mobile p-3">
                        <h1 className='text-center' style={{color: "#fff",borderBottom:"2px solid #fff"}}>SÜRÜCÜLERİMİZ</h1>
                    </Col>
                    <Col xs={12} className="about-description-mobile-container pt-4">
                        <p className='px-3' style={{color:"#fff"}}>{"Sürücülerimiz hizmete başlamadan önce eğitimden geçirilmektedir. Yolcularımızın verdiği puanlar ile hizmet kalitemiz sürekli artmaktadır. "}</p>
                    </Col>
                    <Col xs={12} className="about-picture-mobile-container pt-4">
                        <div className='taxi-driver-mobile w-100 d-flex' />
                    </Col>
                </Col>
            </Row>
            <Row className='drivers-desktop d-none d-md-flex'>
                <Col xs={6} className="about-mobile drivers-mobile flex-column container-padding py-5">
                    <Col xs={12} className="about-title-container-mobile p-3">
                        <h1 style={{color: "#fff",borderBottom:"4px solid #fff"}} className="d-inline">SÜRÜCÜLERİMİZ</h1>
                    </Col>
                    <Col xs={12} className="about-description-mobile-container pt-4">
                        <p className='px-3' style={{color:"#fff",fontSize:"24px"}}>{"Sürücülerimiz hizmete başlamadan önce eğitimden geçirilmektedir. Yolcularımızın verdiği puanlar ile hizmet kalitemiz sürekli artmaktadır. "}</p>
                    </Col>
                </Col>
                <Col xs={6} className="about-picture-mobile-container pt-4">
                    <div className='taxi-driver-mobile w-100 d-flex' />
                </Col>
            </Row>
        </div>
    )
}

export default Drivers; 