import axios from 'axios';
import { useEffect, useContext } from 'react';
import {SocketContext} from "./Context"

const SocketController = () => {
  const client = useContext<any>(SocketContext);

  const connectSocket = () => {
    client.on("connect", () => {

    });
    
    client.on("disconnect", () => {
      });

  }

  useEffect( () => {
    connectSocket()
  }, [])

  return null
}

export default SocketController