import React, { useState, useEffect, ReactComponentElement } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../css/services.css";

const service1 = require("./service1.svg");
const service2 = require("./service2.svg");
const service3 = require("./service3.svg");
const service4 = require("./service4.svg");

function Services() {
  const [selectedService, setSelectedService] = useState(0);

  const services = [
    {
      title: "Önce Güvenliğiniz",
      description:
        "Yolculuğunuzun her anını takip ediyoruz. İstediğiniz her an bize ulaşabilirsiniz.",
      image: service1.default,
    },
    {
      title: "Bakımlı ve Temiz Araçlarımız",
      description:
        "Araçlarımızın temizliği günlük, bakımları ise her ay düzenli olarak yapılmaktadır.",
      image: service2.default,
    },
    {
      title: "7/24 Sanal ve Canlı Destek",
      description:
        "Sanal asistanımız Heyyo 7/24 size yardıma hazır. İstediğinizde canlı temsilcilerimiz ile görüşebilirsiniz.",
      image: service3.default,
    },
    {
      title: "Gizlilik Politikamız",
      description:
        "Telefon numaranızı sürücülerimiz ile paylaşılmamaktadır. Kişisel bilgileriniz gizli kalmaktadır.",
      image: service4.default,
    },
  ];

  return (
    <>
      <Row id="services" className="services px-5 py-5 d-flex d-lg-none">
        <Col xs={12} md={6} className="service-wrapper first-service">
          <div className="service-container">
            <div className="service-icon" />
            <h1 className="text-center pb-4">Önce Güvenliğiniz</h1>
            <p className="text-center">
              Yolculuğunuzun her anını takip ediyoruz. İstediğiniz her an bize
              ulaşabilirsiniz.
            </p>
          </div>
        </Col>
        <Col xs={12} md={6} className="service-wrapper second-service">
          <div className="service-container">
            <div className="service-icon" />
            <h1 className="text-center pb-4">Gizlilik Politikamız</h1>
            <p className="text-center">
              Telefon numaranızı sürücülerimiz ile paylaşılmamaktadır. Kişisel
              bilgileriniz gizli kalmaktadır.
            </p>
          </div>
        </Col>
        <Col xs={12} md={6} className="service-wrapper third-service">
          <div className="service-container">
            <div className="service-icon" />
            <h1 className="text-center pb-4">Bakımlı ve Temiz Araçlarımız</h1>
            <p className="text-center">
              Araçlarımızın temizliği günlük, bakımları ise her ay düzenli
              olarak yapılmaktadır.
            </p>
          </div>
        </Col>
        <Col xs={12} md={6} className="service-wrapper fourth-service">
          <div className="service-container">
            <div className="service-icon" />
            <h1 className="text-center pb-4">7/24 Sanal ve Canlı Destek</h1>
            <p className="text-center">
              Sanal asistanımız Heyyo 7/24 size yardıma hazır. İstediğinizde
              canlı temsilcilerimiz ile görüşebilirsiniz.
            </p>
          </div>
        </Col>
      </Row>

      <Row id="services" className="services d-none d-lg-flex py-5 ">
        <div className="navigation-wrapper w-100 d-flex justify-content-around align-items-center">
          {services.map((service, index) => {
            return (
              <p
                className={`text-center m-0 ${
                  index === selectedService ? "active" : ""
                }`}
                onClick={() => setSelectedService(index)}
              >
                {service.title}
                <div className="circle"></div>
              </p>
            );
          })}
        </div>
        <Col xs={12} className="py-5">
          {services.map((service, index) => {
            return (
              <Row
                className={` ${
                  index !== selectedService
                    ? "invisible d-none position-absolute"
                    : "visible"
                } `}
              >
                <Col xs={6} className="service-left-wrapper">
                  <div className="service-description d-flex flex-column justify-content-center h-100">
                    <h1>{services[selectedService].title}</h1>
                    <p>{services[selectedService].description}</p>
                  </div>
                </Col>
                <Col xs={6} className="service-right-wrapper px-5">
                  <div className="service-image d-flex flex-column justify-content-center h-100">
                    <img src={services[selectedService].image} />
                  </div>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </>
  );
}

export default Services;
