import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../css/navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faBars,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { useAppSelector } from "../redux/store";
import { IoPersonOutline } from "react-icons/io5";

interface NavbarProps {
  setMobileMenu: any;
}
function Navbar(props: NavbarProps) {
  const userData = useAppSelector((state) => state.userData);
  console.log(userData);

  const userImage = userData.picture ? (
    <img src={userData.picture} alt="user" className="w-100 h-100" />
  ) : (
    <IoPersonOutline />
  );
  return (
    <>
      <div className="navbar container-padding">
        <Col xxl={1} xl={1} lg={1} md={1} sm={2} xs={2} className="logo-col">
          <a href="https://heytaksi.net/" className="d-none d-md-flex">
            <img
              src="/img/logo-white.png"
              className="logo"
              alt="HeyTaksi Brand"
            />
          </a>
          <div className="mobile-nav-icon-wrapper d-flex d-md-none">
            <div
              className="mobile-nav-icon"
              onClick={() => props.setMobileMenu(true)}
            >
              <FontAwesomeIcon icon={faBars} />
            </div>
          </div>
        </Col>
        <Col
          xxl={9}
          xl={9}
          lg={10}
          md={10}
          sm={1}
          className="nav-container d-md-flex d-none"
        >
          <ul className="nav-ul py-3 px-4">
            <a href="#root">
              <li className="h-100">Anasayfa</li>
            </a>
            <a href="#howto">
              <li className="h-100">Nasıl Kullanılır?</li>
            </a>
            <a href="#about">
              <li className="h-100">Hakkımızda</li>
            </a>
            <a href="#drivers">
              <li className="h-100">Sürücülerimiz</li>
            </a>
            <a href="#driverform">
              <li className="h-100">Sürücü Başvuru</li>
            </a>
          </ul>
        </Col>
        <Col
          xxl={1}
          xl={1}
          lg={1}
          md={1}
          className="d-flex d-md-flex d-none align-items-center justify-content-center"
        >
          <div className="phone-wrapper pe-xl-2 pe-md-3">
            <a href="tel:+908503040320">
              <div className="phone-container d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={faPhone} />
              </div>
            </a>
          </div>
          <div className="social-wrapper d-flex align-items-center justify-content-center">
            <p className="text-center m-0 ps-3">TR</p>
          </div>
          <div className="user-wrapper ">
            <FontAwesomeIcon icon={faUser} />
          </div>
        </Col>
        <Col
          xs={8}
          className="nav-title-container d-flex d-md-none align-items-center justify-content-center"
        >
          <h1 className="m-0">
            hey<span>taksi</span>transfer
          </h1>
        </Col>
        <Col
          md={1}
          sm={2}
          xs={2}
          className="d-md-none d-flex pe-3 logo-col justify-content-end"
        >
          <div className="mobile-nav-icon-wrapper d-flex d-md-none">
            <div className="mobile-nav-icon">{userImage}</div>
          </div>
        </Col>
      </div>
    </>
  );
}

export default Navbar;
