import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import {
  actions as splashActions,
  reducer as splashReducer,
} from "./slices/SplashSlice";

import {
  actions as userActions,
  reducer as userReducer,
} from "./slices/UserSlice";

import {
  actions as popupActions,
  reducer as popupReducer,
} from "./slices/PopupSlice";

const store = configureStore({
  reducer: {
    splashData: splashReducer,
    userData: userReducer,
    popupData: popupReducer,
  },
});

export default store;

export { splashActions, userActions, popupActions };

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
